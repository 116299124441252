require('system/bootstrap').load({
    /**
     * setup
     */
    setup () {
        require('bootstrap/dist/css/bootstrap.min.css')
        require('style/website/front/index.scss')

        /**
         * load application libraries
         */ 
        require('app/common')
        require('app/front/common')
    },

    /**
     * load context modules
     */
    modules (module, action) {
        require(`app/front/modules/${module}.js`).default.resolve(action)
    }
})
