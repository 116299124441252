import $ from 'jquery'
import LazyLoad from "vanilla-lazyload";
// import analytics from 'utils/analytics'

const controller = () => {

    const lazyload = new LazyLoad({
        container: document.querySelector('div.photos-grid'),
        threshold: 150,
        callback_error (element) {
            // remove images that could not be loaded
            element.closest('div.item').remove()
        }
    })

    const initInfinityScroll = () => {

        let $container = $('div.photos-grid')
        // ------------------------------
        let resourceUri = $container.data('fetch-action')

        if (! resourceUri) {
            return;
        }

        const triggerScrollEvent = () => {
            let rect = $container[0].getBoundingClientRect();

            if ($(window).height() >= rect.bottom - 250) {
                loadMoreContent()
            }
        }

        let loadMoreContent = () => {
            let $image = $container.find('div.item:last')

            let params = {
                from_photo_id: $image.data('photo-id')
            }

            $(window).off('scroll.infinite')

            $.ajaxCall(resourceUri, params, (response) => {
                let content = response.data.content || false
                if (! content) {
                    return
                }

                $container.append(content)

                lazyload.update()

                $(window).on('scroll.infinite', triggerScrollEvent)
            })
        }

        $(window).on('scroll.infinite', triggerScrollEvent).trigger('scroll.infinite')
    }

    // --------------------------------------------------------------------------

    const initKeyboardNavigation = () => {
        document.addEventListener('keydown', (evt) => {
            if (['input', 'textarea'].includes(evt.target.tagName.toLowerCase())) {
                return
            }

            if (evt.repeat) {
                return  // prevent button hold action
            }

            if (evt.key  == 'ArrowLeft') {
                const element = document.getElementById('prev-photo')
                if (element) {
                    element.click()
                }

                return
            }

            if (evt.key == 'ArrowRight') {
                const element = document.getElementById('next-photo')
                if (element) {
                    element.click()
                }

                return
            }
        })
    }

    // --------------------------------------------------------------------------

    const initSlideshow = () => {

        const $photoContainer = $('#photo-container')

        const $metadataContainer = $('div.metadata-container')

        const $gridContainer = $('div#grid')

        const updatePageView = function (photo) {

            // ------------------------------------------------
            // ------------- UPDATE PAGE METADATA -------------
            // ------------------------------------------------

            $('head').find('title').text(photo.page.title)
            $('head').find('meta[name="description"]').attr('content', photo.page.description)
            $('head').find('meta[property="og:title"]').attr('content', photo.page['og:title'])
            $('head').find('meta[property="og:description"]').attr('content', photo.page['og:description'])
            $('head').find('meta[property="og:url"]').attr('content', `https://${window.location.host}/photos/${photo.id + window.location.search}`)
            $('head').find('meta[property="og:image"]').attr('content', photo.images['1600w'])
            $('head').find('meta[property="og:image:width"]').attr('content', 1600)
            $('head').find('meta[property="og:image:height"]').attr('content', Math.ceil(1600 / photo.exif.aspect_ratio))
            $('head').find('meta[property="twitter:title"]').attr('content', photo.page['twitter:title'])

            $('head').find('link[rel="canonical"]').attr('href', `https://${window.location.host}/photos/${photo.id + window.location.search}`)
            $('head').find('script#ldImageObject').text(JSON.stringify(photo.page.json_ImageObject))

            $('head').find('link[rel="prev"]').remove()
            if (photo.prev) {
                $('head').append(`<link rel="prev" href="${photo.prev.link}"/>`)
            }

            $('head').find('link[rel="next"]').remove()
            if (photo.next) {
                $('head').append(`<link rel="next" href="${photo.next.link}"/>`)
            }

            // ------------------------------------------------
            // ------------ UPDATE PHOTO CONTAINER ------------
            // ------------------------------------------------

            $photoContainer.css('padding-bottom', `${100 / photo.exif.aspect_ratio}%`)
            $photoContainer.html(`
                ${photo.prev ? `
                    <a id="prev-photo" data-photo-id="${photo.prev.id}" href="${photo.prev.link}" title="View prev sample photo" class="left-side single-photo">
                        <i class="left-arrow"></i>
                    </a>
                ` : ''}

                <img data-photo-id="${photo.id}" data-photo-orientation="${photo.exif.aspect_ratio > 1 ? 'landscape' : 'portrait'}" src="${photo.images['1600w']}" srcset="${photo.images['900w']} 900w, ${photo.images['1600w']} 1600w" alt="${photo.page['image:alt']}" class="img-responsive" referrerpolicy="same-origin">

                ${photo.next ? `
                    <a id="next-photo" data-photo-id="${photo.next.id}" href="${photo.next.link}" title="View next sample photo" class="right-side single-photo">
                        <i class="right-arrow"></i>
                    </a>
                ` : ''}
            `)

            // ------------------------------------------------
            // ---------------- UPDATE SIDEBAR ----------------
            // ------------------------------------------------

            $metadataContainer.html(`
                <div>
                    <div>
                        <strong>Model:</strong>
                        <span>
                            ${photo.model.is_valid ? `
                            <a href="${photo.model.links.gallery}" title="${photo.model.title} sample photos" class="truncate-text" style="max-width: 72%">${photo.model.title}</a>
                            <a href="${photo.model.links.stats}" title="${photo.model.title} details" class="photo-to-gear">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" class="meta-pie">
                                    <path d="M12 13.9l8.5 8.5c-2.2 2.2-5.2 3.6-8.5 3.6-6.6 0-12-5.4-12-12s5.4-12 12-12v11.9zM14.9 14h12.1c0 3.3-1.4 6.4-3.6 8.5zM26 12h-12v-12c6.6 0 12 5.4 12 12z"/>
                                </svg>
                            </a>
                            ` : `
                            <div class="truncate-text" style="max-width: 72%" title="${photo.model.title}">${photo.model.title}</div>
                            `}
                        </span>
                    </div>
                    ${photo.lens ? `
                    <div>
                        <strong>Lens:</strong>
                        <span>
                            ${photo.lens.is_valid ? `
                            <a href="${photo.lens.links.gallery}" title="${photo.lens.title} sample photos" class="truncate-text" style="max-width: 72%">${photo.lens.title}</a>
                            <a href="${photo.lens.links.stats}" title="${photo.lens.title} details" class="photo-to-gear">
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" class="meta-pie">
                                    <path d="M12 13.9l8.5 8.5c-2.2 2.2-5.2 3.6-8.5 3.6-6.6 0-12-5.4-12-12s5.4-12 12-12v11.9zM14.9 14h12.1c0 3.3-1.4 6.4-3.6 8.5zM26 12h-12v-12c6.6 0 12 5.4 12 12z"/>
                                </svg>
                            </a>
                            ` : `
                            <div class="truncate-text" style="max-width: 72%" title="${photo.lens.title}">${photo.lens.title}</div>
                            `}
                        </span>
                    </div>` : ''}
                </div>

                <div class="exif-list">
                ${photo.exif.focal_length ? `
                    <div>
                        <strong>Focal Length:</strong>
                        <span>${photo.exif.focal_length} mm</span>
                    </div>
                `: ''}
                ${photo.exif.aperture ? `
                    <div>
                        <strong>Aperture:</strong>
                        <span>f/${photo.exif.aperture}</span>
                    </div>
                `: ''}
                ${photo.exif.exposure_time ? `
                    <div>
                        <strong>Exposure:</strong>
                        <span>${photo.exif.exposure_time} sec</span>
                    </div>
                `: ''}
                ${photo.exif.iso ? `
                    <div>
                        <strong>ISO:</strong>
                        <span>${photo.exif.iso}</span>
                    </div>
                `: ''}
                ${photo.exif.metering_mode ? `
                    <div>
                        <strong>Metering Mode:</strong>
                        <span>${photo.exif.metering_mode}</span>
                    </div>
                `: ''}
                ${photo.exif.exposure_mode ? `
                    <div>
                        <strong>Exposure Mode:</strong>
                        <span>${photo.exif.exposure_mode}</span>
                    </div>
                `: ''}
                ${photo.exif.flash ? `
                    <div>
                        <strong>Flash:</strong>
                        <span>${photo.exif.flash}</span>
                    </div>
                `: ''}
                ${photo.author ? `
                    <div>
                        <strong>Credits:</strong>
                        <a href="${photo.source}"${photo.provider != 'explorecams' ? ' rel="noopener nofollow noreferrer"' : ''} target="_blank" class="source">${photo.author}</a>
                    </div>
                `: ''}
                ${photo.license ? `
                    <div class="license">
                        <a href="${photo.license}" rel="license${photo.provider != 'explorecams' ? ' noopener nofollow noreferrer' : ''}" target="_blank">License</a>
                        <div>Free for commercial use, no attribution required</div>
                    </div>
                `: ''}
                </div>
            `)

            // ------------------------------------------------
            // ---------------- UPDATE GRID -------------------
            // ------------------------------------------------

            $gridContainer.find('div.item').remove()
            $gridContainer.append(photo.suggestions)

            lazyload.update()

            window.scrollTo({top: 0})

            // ------------------------------------------------
            // ----------- UPDATE ADs and Analytics -----------
            // ------------------------------------------------

            document.dispatchEvent(
                new CustomEvent('explorecams.onNavigate', {
                    page: 'single-photo'
                })
            )

            if (window.gtag) {
                window.gtag('event', 'page_view', {
                    page_title: document.title,
                    page_location: window.location.href,
                    path_path: window.location.pathname
                });
            }

        }

        // ---------------------------------------------------------

        const photoCache = {}

        const getPhotoResource = async (hash) => {
            if (! photoCache[hash]) {
                const response = await fetch(`/api/photos/${hash}` + window.location.search).then(response => response.json())
                photoCache[hash] = response.data
            }

            return photoCache[hash]
        }

        // ---------------------------------------------------------

        $photoContainer.on('click', 'a#prev-photo, a#next-photo', async (evt) => {
            evt.preventDefault()

            let hash = $(evt.currentTarget).data('photo-id')

            let photo = await getPhotoResource(hash)
            if (photo) {
                window.history.pushState({}, null, `/photos/${hash}` + window.location.search)

                updatePageView(photo)

            }

        })

        window.addEventListener('popstate', async (evt) => {

            let hash = window.location.pathname.split('/').at(-1)

            let photo = await getPhotoResource(hash)
            if (photo) {
                updatePageView(photo)
            }

        })

    }

    (function() {

        initInfinityScroll()

        initKeyboardNavigation()

        initSlideshow()

        $(document).on('click', 'button[name="report"]', evt => {
            $.ajaxCall($(evt.target).data('action'), () => alert('Photo has been reported.'))
        })

    })()
}

export default require('system/controller').create(controller)
