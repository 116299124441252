import $ from 'jquery'
import 'bootstrap/js/tab'

import 'vendor/drawDoughnutChart.modified'

const controller = {}

controller.global = () => {
    // global stats specific code
}

controller.models = () => {
    // models stats specific code

    let initBarChart = () => {
        let labels = []
        let series = []

        $('#chart_stats > li').each((index, item) => {
            labels.push($(item).data('title'))
            series.push({
                value:parseInt($(item).data('value')),
                meta: {
                    model: {
                        ref: `#model-${index}`,
                        title: $(item).data('title')
                    }
                }
            })
        })

        labels = labels.reverse()
        series = series.reverse()

        // --------------------------------------

        new Chartist.Bar('#cameras-bar-chart', {
            labels,
            series: [series]
        }, {
            fullWidth: true,
            horizontalBars: true,
            chartPadding: {
                left: 35,
                right: 35
            },

            axisY: {
                showGrid: false,
                offset: 150
            }
        }).on('draw', data => {
            if (data.type !== 'bar') {
                return
            }

            let $element = $(data.element._node)
            // ---------------------------------
            let label = new Chartist.Svg('text')
                .text(`${data.value.x} photos`)
                .attr({
                    x: data.x1 + (data.element.width() * .5),
                    y: data.y1 + (16 * 0.25),
                    "text-anchor": "middle",
                    class: 'ct-bar-text'
                })

            $element.on('mouseenter', () => {
                data.group.append(label)
            })

            $element.on('mouseleave', () => {
                label.remove()
            })

            $element.on('click', () => {
                let $ref = $(data.meta.model.ref)
                if ($ref.length) {
                    window.scrollTo({top: $ref.offset().top - 30, behavior: 'smooth'})
                }
            })
        })
    }

    $(window).one('load', () => {
        initBarChart()
    })

}

let initStatsPieChart = () => {
    const colors = ['#3366CC', '#990099', '#109618', '#FF9900', '#DC3912', '#000000']

    $('div[data-chart]').each((_, chart) => {
        let data = []

        $(`ul#${$(chart).data('chart')} li`).each((index, item) => {
            data.push({
                value: $(item).data('value'),
                title: $(item).data('title'),
                color: colors[index]
            })
        })

        $(chart).drawDoughnutChart(data, {
            showTipPercentage: true,
            animation: false
        })
    })
}

$(window).one('load', () => {
    initStatsPieChart()
})

export default require('system/controller').create(controller)
