import $ from 'jquery'

import 'bootstrap/js/affix'

const controller = () => {

    let initStatsPieChart = () => {
        $('div[data-chart]').each((_, chart) => {

            let total = 0
            let series = []
            let labels = []

            $(`ul#${chart.id}_stats li`).each((_, item) => {
                let value = parseInt($(item).data('value'))
                series.push(value)

                total += value
            })

            for (let i = 0; i < series.length; i++) {
                let value = (series[i] / total) * 100
                value > 4
                    ? labels.push(`${value.toFixed(1)}%`)
                    : labels.push(' ')
            }

            $(`div#${chart.id}_total`).html(`Total Photos<br /> ${total.toString()}`)

            let responseOptions = [
                ['screen and (max-width: 460px)', {
                    donutWidth: 60
                }]
            ];

            new Chartist.Pie(chart, {
                series,
                labels
            }, {
                donut: true,
                donutWidth: 80,
                donutSolid: true,
                showLabel: true
            }, responseOptions)
        })
    }

    $(function() {
        initStatsPieChart()

        // ------------------------------------------------------

        $('.side-gear').affix({offset: {top: 260}})

        $(window).on('resize', () => {
            $('.side-gear').css('width', $('.left-side').width())
        }).trigger('resize')

        // ------------------------------------------------------

        $('#more').on('click', () => {
            $('.list-wrap').toggleClass('more-gear')
        })

        // ------------------------------------------------------

        $("a[href^='#']").on('click', evt => {
            evt.preventDefault()

            let $ref = $($(evt.target).attr('href'))
            if ($ref.length) {
                window.scrollTo({top: $ref.offset().top - 80, behavior: 'smooth'})
            }
        })
    })
}

export default require('system/controller').create(controller)
